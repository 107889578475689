import styled from 'styled-components';
import AppColors from '../../../styles/colors';
export const Body = styled.div`
  padding: 40px 90px 20px;

  @media (max-width: 1199px) {
    padding: 20px 50px 20px 50px;
  }
  @media (max-width: 767px) {
    padding: 35px 35px 0 35px;
  }

`;

export const HeaderH3 = styled.h3`
  position: relative;
  margin: 0px 0px 40px;
  font-family: interstate, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 35px;
  line-height: 40px;
  color: rgb(0, 105, 170);
  text-align: center;
  @media (max-width: 767px) {
    margin: 0px 0px 30px;
    font-size: 20px;
    line-height: 22px;
  }
`;

export const HeaderH2 = styled.h2`
  position: relative;
  margin: 0px 0px 40px;
  font-family: interstate, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 35px;
  text-align: left;
  line-height: 40px;
  color: rgb(0, 105, 170);
  @media (max-width: 767px) {
    margin: 0px 0px 30px;
    font-size: 20px;
    line-height: 22px;
  }
`;
