import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { Col, Row } from 'react-bootstrap';
import { FormTypes, sectionList } from '../../../constants/queryFragments';
import loadable from '@loadable/component';
import * as Styled from './contentListWith3ItemsGreyBackgroundLeftAlignedStyles';
import {
  Body,
  HeaderH2,
  MainContainerGrey,
  HeaderH3,
  ParaText,
  HeadingH4,
  UnorderedList,
  LinkStyle,
  ListItem,
  
  HorizontalBreak,
} from '../../../styles';
import isExternalUrl, { addTrailingSlash, isExternalUrlHref } from '../../../utils';

const ContentListWith3ItemsGreyBackgroundLeftAligned = ({ sectionData }) => {
  const [loaded, setLoaded] = React.useState(false);
  React.useEffect(() => {
    setLoaded(true);
  }, []);
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => (
        <Styled.HeaderH2>{children}</Styled.HeaderH2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Styled.HeaderH3 textAlign="center">{children}</Styled.HeaderH3>
      ),
      [BLOCKS.HEADING_4]: (node, children) => <HeadingH4>{children}</HeadingH4>,
      [BLOCKS.PARAGRAPH]: (node, children) => <ParaText>{children}</ParaText>,
      [BLOCKS.UL_LIST]: (node, children) => (
        <UnorderedList>{children}</UnorderedList>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => <ListItem>{children}</ListItem>,
      [INLINES.HYPERLINK]: (node, children) => (
        <LinkStyle
          href={
            isExternalUrlHref(node?.data?.uri)
              ? node?.data?.uri
              : addTrailingSlash(
                 process.env.GATSBY_DOMAIN_URL + node?.data?.uri
              )
          }
          target={isExternalUrl(node?.data?.uri) ? '_blank' : '_self'}
        >
          {children}
        </LinkStyle>
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };

  return !loaded ? (
    <>
      <MainContainerGrey>
        <Styled.Body>
          {sectionData?.header?.trim() && (
            <Styled.HeaderH2>
              {sectionData?.header}
            </Styled.HeaderH2>
          )}
          {sectionData?.subtext?.trim() && (
            <HeaderH3>{sectionData?.subtext}</HeaderH3>
          )}
          {sectionData && (
            <>
              {sectionData?.listDetails &&
                documentToReactComponents(
                  JSON.parse(sectionData?.listDetails?.raw),
                  optionsMainStyle
                )}
              <Row>
                {sectionData?.list &&
                  sectionData?.list.map((section, index) => {
                    if (
                      section?.type &&
                      sectionList?.includes(section?.type) &&
                      !FormTypes?.includes(section?.type)
                    ) {
                      const SectionComponent = loadable(() =>
                        import(
                          `../../../components/dynamic/${section?.type}/${section?.type}`
                        )
                      );
                      return (
                        <Col xs={12} lg={4} key={index}>
                          <SectionComponent key={index} sectionData={section} />
                        </Col>
                      );
                    }
                  })}
              </Row>
            </>
          )}
        </Styled.Body>
      </MainContainerGrey>
    </>
  ) : (
    <>
      <MainContainerGrey>
        <Styled.Body>
          {sectionData?.header?.trim() && (
            <Styled.HeaderH2>
              {sectionData?.header}
            </Styled.HeaderH2>
          )}
          {sectionData?.subtext?.trim() && (
            <HeaderH3>{sectionData?.subtext}</HeaderH3>
          )}
          {sectionData && (
            <>
              {sectionData?.listDetails &&
                documentToReactComponents(
                  JSON.parse(sectionData?.listDetails?.raw),
                  optionsMainStyle
                )}
              <Row>
                {sectionData?.list &&
                  sectionData?.list.map((section, index) => {
                    if (section?.type && sectionList?.includes(section?.type)) {
                      const SectionComponent = loadable(() =>
                        import(
                          `../../../components/dynamic/${section?.type}/${section?.type}`
                        )
                      );
                      return (
                        <Col xs={12} lg={4} key={index}>
                          <SectionComponent key={index} sectionData={section} />
                        </Col>
                      );
                    }
                  })}
              </Row>
            </>
          )}
        </Styled.Body>
      </MainContainerGrey>
    </>
  );
};
export default ContentListWith3ItemsGreyBackgroundLeftAligned;
